<template>
  <div class="springAwakening2023_main">

    <nav-bar header-title="超鹿学院商城" backColor="#fff" :show-back="true" :header-back="newAppBack" ref="head" />
    <div class="headbg">
      <img src="https://img.chaolu.com.cn/ACT/temp-activity/sdcollege-mall/poster.jpg" class="head-img" alt="" />
      <div @click="showRule = true" class="rule-btn row-center-center">规则</div>
    </div>
    <!--团课卡-->
    <!-- <div class="goodsbox col-start-center" style="margin-top: 1.6vw">
      <div class="basebg">
        <div class="goods-model" v-for="(citem, index) in cardMap" :key="index">
          <div class="pub_full">
            <div class="goods-img"><img :src="citem.picUrl" /></div>
            <div class="pub_onefull col-between-start">
              <div>
                <div class="goods-name">{{ citem.itemName }}</div>
                <div class="invalid-time" v-if="citem.expiryDateDescribe" v-html="citem.expiryDateDescribe"></div>
                <div class="label-line">
                  <div class="label-model" v-if="citem.discountRate">
                    <div class="scale">{{ citem.discountRate }}</div>
                  </div>
                  <div class="label-model" v-if="citem.save">
                    <div class="scale">{{ citem.save }}</div>
                  </div>
                </div>
              </div>
              <div class="row-between-center">
                <div class="price-line row-start-end">
                  <span class="dl">¥</span>
                  <span class="price">{{ citem.salePrice }}</span>
                  <span v-if="citem.originalPrice" class="oldprice">¥{{ citem.originalPrice }}</span>
                </div>
                <div class="pub_avg line2">
                  <div class="buy" :class="{ gray: citem.itemStatus != 'CAN_BUY' }" @click="buyCard(citem, 21)">{{
                    buttonText(citem)
                  }}
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div> -->
    <van-popup v-model="showRule" position="bottom" safe-area-inset-bottom class="pop-rule">
      <div class="pop-rule-title">
        {{ rules.title }}
        <van-icon name="cross" class="pop-rule-close" color="#fff" @click="showRule = false" />
      </div>
      <div class="pop-rule-content">
        <div v-for="(item, index) in rules.content" :key="index" v-html="item"></div>
      </div>
    </van-popup>

  </div>
</template>
<script>
import { newAppBack, initBack, appPay, appPaySuccess } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'

import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'
import { isEmpty } from '@/lib/utils'

const activityNo = 'shiWuSale';
const webUrl = `${window.location.origin}/#/temp-activity/sdcollege-mall`
export default {
  components: {
    navBar,
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {

      showRule: false,

      activeKey: 3,

      cardMap: [
        {
          expiryDateDescribe: "有效期：购买后90天有效",
          itemId: "20230824001",
          itemName: "美式拉伸课程",
          itemStatus: "CAN_BUY",
          picUrl: "https://img.chaolu.com.cn/ACT/temp-activity/sdcollege-mall/good.jpg",
          salePrice: "199",
          originalPrice: '5800',
          save: '不可转赠'
        },
        {
          expiryDateDescribe: "有效期：购买后90天有效",
          itemId: "20230628002",
          itemName: "超鹿学院筋膜刀放松技术课程",
          itemStatus: "CAN_BUY",
          picUrl: "https://img.chaolu.com.cn/ACT/temp-activity/sdcollege-mall/good.png",
          salePrice: "99",
          originalPrice: '1980',
          save: '不可转赠'
        },
        {
          expiryDateDescribe: "有效期：购买后90天有效",
          itemId: "20230628001",
          itemName: "超鹿学院筋膜刀康复技术课程",
          itemStatus: "CAN_BUY",
          picUrl: "https://img.chaolu.com.cn/ACT/temp-activity/sdcollege-mall/good.png",
          salePrice: "499",
          originalPrice: '9800',
          save: '不可转赠'
        },
      ],//  礼品卡
      microCoursePackageMap: [],//  小班课
      equityCardMap: [], // 团课月卡
      zzkList: [], // 自助卡筛选后展示用
      zzkIndex: 0, // 自助卡第几类
      ticketEquityCardMap: [], // 自助卡二重数组
      scheduleCoursePackageMap: {}, // 团课
      perCoursePackageMap: {},// 私教
      rules: {
        title: '活动规则',
        content: [
          '美式拉伸课程：',
          '1、本券仅限超鹿学院美式拉伸课程使用；',
          '2、凭本券到上课地点核销兑换课程，核销券后在当天完成课程学习，仅限本人单次使用；',
          '3、本券不可转赠，不可分为多次上课，取消后不返还。',
          '<br />',
          '超鹿学院筋膜刀放松技术课程：',
          '1、本券仅限超鹿学院筋膜刀放松技术课程开课使用；',
          '2、凭本券到上课地点核销兑换课程，核销券后在当天完成课程学习，仅限本人单次使用；',
          '3、本券不可转赠，不可分为多次上课，取消后不返还。',
          '<br />',
          '超鹿学院筋膜刀康复技术课程：',
          '1、本券仅限超鹿学院筋膜刀康复技术课程开课使用；',
          '2、凭本券到上课地点核销兑换课程，核销券后在当天完成课程学习，仅限本人单次使用；',
          '3、本券不可转赠，不可分为多次上课，取消后不返还。'
        ]
      },
      stickyHeight: 0,
    }
  },
  methods: {
    newAppBack,
    isEmpty,
    buttonText(item = {}) {
      const status = item.itemStatus;
      const limit = item.hasBuyLimit;
      const json = {
        'NOT_STARTED': '待开启',
        'EXPIRED': '已结束',
        'SOLD_OUT': '已售罄',
        'HAVE_BUY': '已购买',
        'CAN_BUY': '立即购买',
        'NOT_BUY': '不可购买'
      }
      let text = json[status] || '立即购买';
      if (status == 'CAN_BUY' && limit) {
        text = '限量抢';
      }
      return text;
    },


    async buyCard(item, type) {

      if (item.itemStatus !== 'CAN_BUY') {
        return
      }
      // const res = await this.checkCanBuy(item);

      // 服务端需要 做个转换
      const obj = JSON.parse(JSON.stringify(item))
      obj.itemId = obj.isFlashSale ? obj.itemId.split('-')[0] : obj.itemId

      this.payAction(obj, type)
    },
    payAction(info, type) {
      let url = `/pages/webView/index?webUrl=${webUrl}&userId=1&token=1`
      let payData = {
        subject: info.itemName,
        extendsJson: {
          appVersion: '2.1.11',
          seriesName: '超鹿学院商城',
          inviteUserId: this.inviteId,
        },
        totalAmount: info.salePrice,
        venueId: '31',
        goodsNum: type === 12 ? '1' : undefined,
        isApp: '1',
        type: type, // 礼品卡12  课包10 月卡
        userId: this.params.userId,
        token: this.params.token,
        extendsType: '4',
        mouldId: info.itemId,
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: info.itemName,
      }
      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      }
      appPay(payData, url);
      console.log(payData, url);
      if (this.appTypeStr !== 'mini') {
        appPaySuccess().then(() => {
          this.paySuccess();
          this.$toast('支付成功');
        })
      }
    },
    paySuccess() {

    },
    checkCanBuy(info) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios.post(this.baseURLApp + '/anniversary/sale/checkCanBuy', {
        ...this.params,
        itemId: info.itemId
      }).then((r) => {
        this.$toast.clear()
        return r.code
      }).catch(() => {
        this.$toast.clear()
        return 0
      })
    },

  },
  mounted() {
    setTimeout(() => {
      this.stickyHeight = this.$refs.head.$el.offsetHeight
    }, 1000)
  },
  async created() {
    initBack();

    await this.$getUserId();
    this.params = {
      activityNo,
      userId: this.userId,
      token: this.token,
      hasWx: true
    }

    appPaySuccess().then(() => {
      this.paySuccess();
    })
  },

}
</script>
<style lang="less" scoped>
.springAwakening2023_main {
  min-height: 100vh;
  background: #6C9AF2;
  padding-bottom: 60px;
  box-sizing: border-box;

  div {
    box-sizing: border-box;
  }

  img {
    vertical-align: top;
    width: 100%;
  }



  .headbg {
    position: relative;
  }

  .rule-btn {
    width: 88px;
    height: 56px;
    font-size: 24px;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 28px 0 0 28px;
    position: absolute;
    top: 318px;
    right: 0;
  }



  .goodsbox {
    padding: 0 32px 14px;
    margin-top: 40px;

    .basebg {
      width: 100%;

      background: rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      position: relative;
      padding: 28px 24px 12px;
      margin-bottom: 42px;
      min-height: 300px;
    }



    .goods-model {
      background: #fff;
      border-radius: 16px;
      margin-bottom: 20px;
      padding: 26px;
      //height: 292px;

      .goods-img {
        width: 180px;
        height: 180px;
        background-color: rgba(255, 255, 255, 0.7);
        margin-right: 30px;
        border-radius: 10px;
        overflow: hidden;
        flex-shrink: 0;

        img {
          object-fit: cover;
        }
      }

      .goods-name {
        font-weight: bold;
        color: #242831;
        font-size: 28px;
        padding-top: 2px;
      }

      .describe {
        width: 96px;
        height: 36px;
        border-radius: 4px;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 20px;
        background: #FF6C01;
        margin-bottom: 20px;
      }

      .label-line {
        margin: 14px 0 10px;
        white-space: nowrap;

        .label-model {
          padding: 0 10px;
          height: 30px;

          background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
          border-radius: 4px;
          color: #B12E1D;
          font-weight: bold;
          font-size: 20px;
          margin-right: 12px;
          line-height: 1;
          display: inline-flex;
          align-items: center;

          .scale {
            transform: scale(0.9);
            transform-origin: 50% 50%;
          }
        }

        .limit {
          padding: 0;
          background: #79AF52;
        }
      }

      .invalid-time {
        color: #3C454E;
        font-size: 20px;
        line-height: 16px;
        margin-top: 16px;
      }

      .price-line {

        .dl {
          font-size: 22px;
          color: #F03C18;
          font-weight: bold;
          margin-right: 4px;
        }

        .price {
          font-size: 32px;
          color: #F03C18;
          font-weight: bold;
          margin-right: 9px;
          //position: relative;
          line-height: 32px;
          //top: 2px;
        }

        .oldprice {
          text-decoration: line-through;
          color: #6C727A;
          font-size: 20px;
        }
      }

      .line2 {
        align-items: center;


        .buy {
          width: 136px;
          height: 50px;
          background: linear-gradient(0deg, #F35947 0%, #FBA27B 100%);
          border-radius: 25px;
          color: #fff;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
        }

        .gray {
          color: #6C727A;
          background: #DDDDDD;
          box-shadow: none
        }
      }
    }
  }

  .pop-rule {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;

    .pop-rule-title {
      height: 132px;
      font-weight: bold;
      background: linear-gradient(180deg, #DDF2FF 0%, #FCFEFF 100%);
      font-size: 32px;
      text-align: center;
      line-height: 130px;
      position: relative;

      .pop-rule-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
        font-size: 28px;
      }
    }

    .pop-rule-content {
      color: #242831;
      font-size: 26px;
      padding: 0 52px 90px;
      max-height: 720px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      white-space: pre-line;

      div {
        margin-bottom: 6px;
        line-height: 1.6;
      }
    }
  }


  @bei: 1.5px;

  .bottom-tips {
    color: #FFFFFF;
    font-size: 22px;

    i {
      width: 60px;
      height: 1px;
      background: #FFFFFF;
    }

    span {
      margin: 0 30px;
    }
  }

}
</style>

import { render, staticRenderFns } from "./sdcollege-mall.vue?vue&type=template&id=6be552d1&scoped=true&"
import script from "./sdcollege-mall.vue?vue&type=script&lang=js&"
export * from "./sdcollege-mall.vue?vue&type=script&lang=js&"
import style0 from "./sdcollege-mall.vue?vue&type=style&index=0&id=6be552d1&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6be552d1",
  null
  
)

export default component.exports